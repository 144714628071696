import { getPublicContent } from ".";
import { defaultContentErrorPage } from "../constants";

/**
 * Calls the Static Content API for Errors pages
 *
 * @param {String} errorType the status code to determine the error page we are fetching (i.e. - 403, 404, 500)
 * @returns {Promise.<any>} Promise object that contains the AEM response after transformation
 */
export const getErrorPage = async (errorType = "500") => {
  try {
    const response = await getPublicContent(`/error/${errorType}`);
    return response;
  } catch (err) {
    // Intentionally ignore errors and provide a default response
    return defaultContentErrorPage;
  }
};

export default getErrorPage;
