import React from "react";
import PropTypes from "prop-types";
import PageGrid, { PageCell } from "Components/PageGrid";
import Heading from "Components/Heading";
import Spread from "Components/Spread";
import { PlainLink } from "Components/Link";
import styles from "./FallbackPage.scss";

/**
 * Generic page layout for "fallback" pages (error, maintenance, etc.)
 * @param {Object} props component props
 * @param {String} [props.heading] page heading
 * @param {String} [props.emoji] page emojis for heading
 * @param {String} [props.subheading] page subheading
 * @param {Object[]} [props.link] call to action
 * @param {String} [props.link.text] call to action link text
 * @param {String} [props.link.href] call to action link href
 * @param {boolean} [props.hasWhiteBg] boolean value for background
 * @returns {React.Node} node
 */
const FallbackPage = ({
  children,
  emoji,
  hasWhiteBg,
  heading,
  link,
  subheading,
}) => (
  <PageGrid className={hasWhiteBg ? styles.maintenance : styles.root}>
    <PageCell region="left-main" padded>
      <Spread size="small">
        {subheading && (
          <Heading kind="eyebrow" as="h1">
            {subheading}
          </Heading>
        )}
        {heading && (
          <Heading kind="page" as="h2">
            {heading}
            {emoji && emoji.emojiTitle && emoji.emojiIcon && (
              <span
                role="img"
                className={styles.emoji}
                aria-label={emoji.emojiTitle}
              >
                {emoji.emojiIcon}
              </span>
            )}
          </Heading>
        )}
        {children}
        {link &&
          link.length > 0 &&
          link.map(item => (
            <div key={item.id}>
              <PlainLink autoid={item.id} href={item.link}>
                {item.text}
              </PlainLink>
            </div>
          ))}
      </Spread>
    </PageCell>
  </PageGrid>
);

FallbackPage.propTypes = {
  heading: PropTypes.string,
  emoji: PropTypes.shape({
    emojiIcon: PropTypes.string,
    emojiTitle: PropTypes.string,
  }),
  subheading: PropTypes.string,
  children: PropTypes.node.isRequired,
  link: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      text: PropTypes.string,
      link: PropTypes.string,
    }),
  ),
  hasWhiteBg: PropTypes.bool,
};

FallbackPage.defaultProps = {
  heading: "",
  subheading: "",
  emoji: {
    emojiIcon: "",
    emojiTitle: "",
  },
  link: [
    {
      id: "",
      text: "",
      link: "",
    },
  ],
  hasWhiteBg: false,
};

export default FallbackPage;
