import React from "react";
import BaseError, {
  baseErrorPropTypes,
  baseErrorDefaultProps,
} from "Components/BaseError";
import { getErrorPage as getPage } from "api/error";
import { getOr } from "../../utils";

/**
 * Page for 500 errors
 * @param {string} [autoid='error'] The autoid of the component for automation testing
 * @param {string} [body] In Rich Text format
 * @param {Object} [emoji] The title component data, including the heading and an emoji
 * @param {string} [eyebrow] The eyebrow of the page
 * @param {Object} [heading] The title component data, including the heading and an emoji
 * @param {string} [heading.text] The heading text
 * @param {Object[]} [links] The page links data
 * @param {string} [metaTitle] The string page title name
 * @returns {React.Node} node
 */
const Page = ({
  autoid,
  body,
  emoji,
  eyebrow,
  heading,
  links,
  message,
  metaTitle,
}) => (
  <BaseError
    autoid={autoid}
    body={body}
    emoji={emoji}
    eyebrow={eyebrow}
    heading={heading}
    links={links}
    message={message}
    metaTitle={metaTitle}
  ></BaseError>
);

/**
 * Get the data for this page.
 */
Page.getInitialProps = async () => {
  const response = await getPage("500");
  const emoji = {
    emojiIcon: getOr(null, "heading.emoji", response),
    emojiTitle: getOr(null, "heading.emojiTitle", response),
  };
  const { metaTitle, eyebrow, heading, body, links } = response;
  return {
    body,
    emoji,
    eyebrow,
    heading,
    links,
    metaTitle,
  };
};

Page.displayName = "ServerErrorPage";
Page.propTypes = baseErrorPropTypes;
Page.defaultProps = baseErrorDefaultProps;

export default Page;
