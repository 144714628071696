import { handleError, apiArticle } from ".";
import ArticleDetailDataMapping from "../utils/articleDataMap";
/**
 * Fetch article detail
 * @param {string} articleTitle -article title
 * @param {object} req - request object
 * @returns {Object} data
 */
export const getCurrentArticle = async ({ articlePath }) => {
  try {
    const encodedArticlePath = encodeURIComponent(articlePath);
    const { data } = await apiArticle.get(
      `/articles?title=${encodedArticlePath}`,
    );
    const mapedData = ArticleDetailDataMapping(data);
    return mapedData;
  } catch (err) {
    return handleError(err);
  }
};

/**
 * // Need to find a better way for article navigation as of now we are calling digest api for next entity Id
 * Fetch Daily Digest Card for Navigation on Article detail page
 * @param {string} token - marcel auth token
 * @param {object} req - request object
 * @returns {Object} data
 */
export const getArticles = async () => {
  try {
    const { data } = await apiArticle.get("/digests");
    return data;
  } catch (err) {
    return handleError(err);
  }
};
