import React from "react";
import { bool, oneOf, node, string } from "prop-types";
import clsx from "clsx";
import styles from "./Heading.scss";

const EYEBROW = "eyebrow";
const NORMAL = "normal";
const PAGE = "page";
const SECTION = "section";
const ITEM = "item";
const SMALL = "small";

const kinds = [EYEBROW, NORMAL, PAGE, SECTION, ITEM, SMALL];

const kindStyles = new Map([
  [EYEBROW, styles.eyebrowHeading],
  [PAGE, styles.pageHeading],
  [SECTION, styles.sectionHeading],
  [ITEM, styles.itemHeading],
  [SMALL, styles.smallHeading],
  [NORMAL, null],
]);

/**
 * Base heading component
 * @param {Object} props component props
 * @param {String} props.as HTML tag for root heading element
 * @param {String} props.autoid [autoid=heading] The autoid of the component for automation testing
 * @param {String} props.kind controls how the heading looks
 * @param {Boolean} props.truncate flag for truncated text
 * @returns {React.Node} react node
 */
const Heading = ({
  as: Component,
  autoid,
  children,
  className,
  kind,
  truncate,
  ...props
}) => (
  <Component
    data-autoid={autoid}
    className={clsx(
      styles.root,
      kindStyles.get(kind),
      truncate && styles.truncated,
      className,
    )}
    {...props}
  >
    {children}
  </Component>
);

Heading.kinds = kinds;

Heading.propTypes = {
  as: string,
  autoid: string,
  children: node.isRequired,
  kind: oneOf(Heading.kinds),
  truncate: bool,
};

Heading.defaultProps = {
  as: "div",
  autoid: "heading",
  kind: NORMAL,
  truncate: false,
};

export default Heading;

/**
 * Heading specifically for the page-level hierarchy
 * @param {Object} props component props
 * @returns {React.Node} react node
 */
export const PageHeading = props => (
  <Heading as="h1" kind={PAGE} {...props} />
);

/**
 * Heading specifically for the section-level hierarchy
 * @param {Object} props component props
 * @returns {React.Node} react node
 */
export const SectionHeading = props => (
  <Heading as="h2" kind={SECTION} {...props} />
);

/**
 * Heading specifically for the item-level hierarchy
 * @param {Object} props component props
 * @returns {React.Node} react node
 */
export const ItemHeading = props => (
  <Heading as="h3" kind={ITEM} {...props} />
);

/**
 * Heading specifically for small headings (h4s)
 * @param {Object} props component props
 * @returns {React.Node} react node
 */
export const SmallHeading = props => (
  <Heading as="h4" kind={SMALL} {...props} />
);

/**
 * Heading specifically for item-level metadata
 * @param {Object} props component props
 * @returns {React.Node} react node
 */
export const EyebrowHeading = props => (
  <Heading as="span" kind={EYEBROW} {...props} />
);
