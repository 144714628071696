/* eslint-disable require-jsdoc */
import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { arrayOf, bool, objectOf, shape, string } from "prop-types";
import Head from "Components/Head";
import ArticleCards, {
  digestDataPropTypes,
} from "Components/Card/ArticleCards/ArticleCards";
import FeaturedArticle from "Components/FeaturedArticle";
import GetInTouch, { emailDataPropTypes } from "Components/GetinTouch";
import PageGrid, { PageCell } from "Components/PageGrid";
import PageHero from "Components/Public/Hero";
import { Heading } from "@marcel/react-component-library/es/atoms/Heading";
import { RichText } from "@marcel/react-component-library/es/molecules/RichText";
import { LinkWithFallback } from "@marcel/react-component-library/es/atoms/Link";
import { getPublicContent } from "api/index";
import { getArticles } from "api/articledetail";
import ErrorPage from "../_error";

import styles from "./litehomepage.scss";

const Page = ({
  metaTags,
  hero,
  error,
  getInTouch,
  digestData,
  autoid,
  labels,
}) => {
  const [cardLimit, setCardLimit] = useState(7);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const seeMore = cards => {
    const cardsLength = cards.length || [];
    setCardLimit(cardsLength);
  };

  if (error) {
    return (
      <ErrorPage statusCode={error.status} message={error.message} />
    );
  }

  return (
    <>
      <Head {...metaTags} />

      <div className={clsx(styles.loginSection)}>
        <div className={styles.loginBorder}>
          {hero && hero.subtitle && (
            <RichText
              as="div"
              autoid={`${autoid}_hero_subtitle`}
              className={styles.loginText}
              withStyles
            >
              {hero.subtitle}
            </RichText>
          )}

          {hero && Array.isArray(hero.ctaItems) && (
            <div className={styles.login} region="right-side">
              {hero.ctaItems.map((item, index) => (
                <LinkWithFallback
                  key={item.id || index}
                  className={clsx(
                    "m-button--invert",
                    styles.linkStyles,
                  )}
                  data-autoid={`${autoid}_hero_cta_${index}`}
                  href={item.ctaLink}
                  external={!item.newTab}
                  target={item.target ? item.target : "_blank"}
                >
                  {item.ctaLabel}
                </LinkWithFallback>
              ))}
            </div>
          )}
        </div>
      </div>

      <PageHero
        background={
          <div className={styles.root}>
            {hero && hero.heading && (
              <PageGrid className={styles.heroSection}>
                <PageCell region="left-half" padded>
                  <Heading
                    kind="h1"
                    autoid={`${autoid}_hero_heading`}
                    className="m-vr--medium"
                  >
                    {hero.heading}
                  </Heading>
                </PageCell>

                {hero && hero.body && (
                  <PageCell region="right-half" padded>
                    <RichText
                      as="div"
                      autoid={`${autoid}_hero_body`}
                      withStyles
                    >
                      {hero.body}
                    </RichText>
                  </PageCell>
                )}
              </PageGrid>
            )}
          </div>
        }
      />

      {Array.isArray(digestData) && digestData[0] && (
        <div className={clsx(styles.featureArticle, "region")}>
          <FeaturedArticle labels={labels} {...digestData[0]} />
        </div>
      )}

      <ArticleCards
        title={[labels.recentArticlesLabel]}
        digestData={digestData} // Array of digest card objects
        cardLimit={cardLimit}
        className={clsx(styles.articlesSection, "region", "m-section")}
        labels={labels}
      />

      {Array.isArray(digestData) &&
        digestData.length > cardLimit &&
        labels.digestCtaLabel && (
          <div className={styles.seeMoreContainer}>
            <button
              type="button"
              className={styles.seeMoreCTA}
              onClick={() => seeMore(digestData)}
              data-autoid={`${autoid}_seeMore`}
            >
              {labels.digestCtaLabel}
            </button>
          </div>
        )}

      {getInTouch && (
        <GetInTouch
          className={styles.getinTouch}
          header={getInTouch.heading}
          body={getInTouch.body}
          emailData={getInTouch.items}
        />
      )}
    </>
  );
};

Page.getInitialProps = async ({ query: { communityID } }) => {
  const {
    error,
    getInTouch,
    hero,
    labels,
    metaTags,
  } = await getPublicContent("/landing/homepage");

  // Handle Request-Error
  if (error) {
    return { error };
  }

  // Handle Articles-Digest
  const data = await getArticles();
  // "data" could be an object with "error" when the Request fails
  const digestData = Array.isArray(data) ? data : [];

  const ctaItems = [
    {
      id: "2-externalButton",
      ctaLabel: "External Log In",
      ctaLink: `/rbac/${communityID}`,
      target: "_self",
    },
    {
      id: "0-loginButton",
      ctaLabel: "Internal Log In",
      ctaLink: `/irbac/app/`,
      target: "_self",
    },
  ];

  const initialProps = {
    digestData,
    getInTouch,
    hero:
      communityID !== undefined && hero?.ctaItems
        ? { ...hero, ctaItems }
        : hero,
    labels,
    metaTags,
  };

  return initialProps;
};

Page.propTypes = {
  autoid: string,
  error: shape({
    status: string,
    message: string,
  }),
  getInTouch: shape({
    heading: string,
    items: emailDataPropTypes,
  }),
  digestData: digestDataPropTypes,
  hero: shape({
    heading: string,
    body: string,
    subtitle: string,
    ctaItems: arrayOf(
      shape({
        id: string,
        ctaLabel: string,
        ctaLink: string,
        newTab: bool,
      }),
    ),
  }),
  labels: objectOf(string),
  metaTags: shape({
    title: string,
    description: string,
    keywords: string,
  }),
};

Page.defaultProps = {
  autoid: "publichomepage",
  digestData: [],
  error: null,
  getInTouch: {},
  hero: {},
  labels: {},
  metaTags: {},
};

export default Page;
