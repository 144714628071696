import React from "react";
import clsx from "clsx";
import { string, node } from "prop-types";
import styles from "./Hero.scss";

/**
 * Container that stacks together an image background, text content, and
 * foreground (e.g. button or CTA) while handling responsive adjustments.
 * @param {Object} props component props
 * @param {String} props.as HTML tag to use for root element
 * @param {React.Node} props.background node to use as background layer
 * @param {React.Node} props.foreground node to use as foreground layer
 * @returns {React.Node} node
 */
const Hero = ({
  as: Component,
  background,
  className,
  children,
  foreground,
  ...props
}) => (
  <Component className={clsx(styles.root, className)} {...props}>
    {background}
    <div className={styles.content}>{children}</div>
    {foreground}
  </Component>
);

Hero.propTypes = {
  as: string,
  children: node,
  background: node,
  foreground: node,
};

Hero.defaultProps = {
  as: "div",
  background: null,
  foreground: null,
  children: null,
};

export default Hero;
