import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  ASPECT_RATIOS,
  Image,
} from "@marcel/react-component-library/es/atoms/Image";
import { LaunchIcon } from "@marcel/react-component-library/es/atoms/Icon";
import { ArticleLink } from "../../../Link";
import styles from "./ArticleCard.scss";

/**
 * Generic component to display content in a card format
 * @param {string} image displayed at the top of the card
 * @param {string} title of the card
 * @param {string} description of the card to be displayed below the title
 * @param {string} footer to be displayed at the bottom left corner of the card
 * @param {boolean} externalLink if the card directs to an external link
 * @param {string} className styles to be added
 * @param {string} autoid for automation testing
 * @param {string} author author of card
 * @param {string} estimatedReadTime read time of linked article
 * @param {object} labels Generic labels from API
 * @return {*} The generic card component
 * @constructor
 */
const ArticleCard = ({
  author,
  estimatedReadTime,
  title,
  className,
  path,
  autoid,
  externalLink,
  digestImageDesktop,
  description,
  labels,
  type,
}) => {
  return (
    <div className={clsx(styles.contentCard, className)}>
      <ArticleLink
        autoid={`${autoid}_card_link`}
        className={styles.cardLayout}
        path={path}
        title={title}
        type={type}
      >
        <div className={styles.imageContainer}>
          {digestImageDesktop.value && (
            <Image
              autoid={`${autoid}_image`}
              src={digestImageDesktop.value}
              alt={digestImageDesktop.altText}
              aspectRatio={ASPECT_RATIOS.RATIO3BY2}
            />
          )}
        </div>
        <div className={styles.content}>
          {estimatedReadTime && (
            <p
              data-autoid={`${autoid}_read_time`}
              className={styles.readText}
            >
              {estimatedReadTime}
            </p>
          )}
          <h4 data-autoid={`${autoid}_title`} className={styles.title}>
            {title}
          </h4>
          <p
            data-autoid={`${autoid}_description`}
            className={styles.description}
          >
            {description}
          </p>
          <div className={styles.cardFooter}>
            <div className={styles.footer}>
              <p
                data-autoid={`${autoid}_footer`}
                className={styles.footerText}
              >
                {author && `${labels.byAuthorLabel} ${author}`}
              </p>
            </div>
            {externalLink && (
              <LaunchIcon className={styles.launchIcon} />
            )}
          </div>
        </div>
      </ArticleLink>
    </div>
  );
};
/**
 * The default prop types declaration
 */
ArticleCard.propTypes = {
  author: PropTypes.string,
  estimatedReadTime: PropTypes.string,
  title: PropTypes.string,
  path: PropTypes.string,
  digestImageDesktop: PropTypes.shape({
    value: PropTypes.string,
    altText: PropTypes.string,
  }),
  externalLink: PropTypes.bool,
  description: PropTypes.string,
  autoid: PropTypes.string,
  labels: PropTypes.shape({
    byAuthorLabel: PropTypes.string,
  }),
  type: PropTypes.string,
};

/**
 * The default prop types declaration
 */
ArticleCard.defaultProps = {
  author: "",
  estimatedReadTime: "",
  title: "",
  path: "",
  digestImageDesktop: {
    value: "",
    altText: "",
  },
  description: "",
  externalLink: null,
  autoid: "article_card",
  labels: {
    byAuthorLabel: "",
  },
  type: "",
};

export default ArticleCard;
