import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { DigestHero } from "@marcel/react-component-library/es/molecules/DigestHero";
import styles from "./FeaturedArticle.scss";
import { ArticleLink } from "../Link";

/**
 * Featured Article Component
 * To display the Featured Article on the HomePage,
 * @returns {jsx} returns Featured article
 * @param {string} estimatedReadTime Text displaying the time to read this digest card
 * @param {string} title The title of the digest card
 * @param {string} image The image displayed
 * @param {string} description The description of the digest card
 * @param {string} author Author of the article
 * @param {string} className Styles applied to the component
 * @param {object} labels Generic labels from API
 */
const FeaturedArticle = ({
  title,
  estimatedReadTime,
  path,
  digestImageDesktop,
  author,
  labels,
}) => {
  return (
    <ArticleLink
      path={path}
      title={title}
      className={clsx(styles.noBorder, styles.featuredArticle)}
    >
      <DigestHero
        eyebrow1={estimatedReadTime}
        title={title}
        image={digestImageDesktop.value}
        byline={`${labels.byAuthorLabel} ${author}`}
        link={null}
        showdateBlock={false}
        className={styles.noBorder}
        altText={digestImageDesktop.altText}
      />
    </ArticleLink>
  );
};

FeaturedArticle.propTypes = {
  labels: PropTypes.shape({
    byAuthorLabel: PropTypes.string,
  }),
  estimatedReadTime: PropTypes.string,
  title: PropTypes.string,
  author: PropTypes.string,
  path: PropTypes.string,
  digestImageDesktop: PropTypes.shape({
    value: PropTypes.string,
    altText: PropTypes.string,
  }),
};

FeaturedArticle.defaultProps = {
  estimatedReadTime: null,
  labels: {
    byAuthorLabel: "",
  },
  title: null,
  path: "",
  author: "",
  digestImageDesktop: {
    value: "",
    altText: "",
  },
};

export default FeaturedArticle;
