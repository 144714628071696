import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import styles from "./GetinTouch.scss";

/**
 * The Get in Touch Section
 * @param {String} props.autoid [autoid=get_into_touch] autoid for automation testing
 * @param {string} header The header of the Get in Touch Section
 * @param {string} body The description of the Get in Touch Section
 * @param {string} [className=""] The optional className that allows a custom style class to be applied
 * @param {Array} [emailData=[]] The contact point's email for the various sections
 * @return {React.Node} The React node that makes up the component
 * @constructor
 */
const GetinTouch = ({ autoid, header, body, className, emailData }) => {
  return (
    <div className={clsx(styles.gitSection, className)}>
      {header && (
        <h2
          data-autoid={`${autoid}_header`}
          className={styles.gitHeader}
        >
          {header}
        </h2>
      )}

      <p
        data-autoid={`${autoid}_body`}
        className={clsx(styles.gitBody, "m-vr--large")}
      >
        {body}
      </p>

      <div className={styles.contactSection}>
        {Array.isArray(emailData) &&
          emailData.map((emaildata, index) => (
            <div
              key={`maildata-${emaildata.id || index}`}
              className={styles.contactUs}
            >
              <p
                data-autoid={`${autoid}_${index}_text`}
                className={styles.sectionText}
              >
                {emaildata.footer}
              </p>

              <a
                data-autoid={`${autoid}_${index}_link`}
                className={styles.email}
                href={emaildata.url}
              >
                {emaildata.action}
              </a>
            </div>
          ))}
      </div>
    </div>
  );
};

export const emailDataPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.string,
    url: PropTypes.string,
    action: PropTypes.string,
    footer: PropTypes.string,
  }),
);

GetinTouch.propTypes = {
  autoid: PropTypes.string,
  header: PropTypes.string,
  body: PropTypes.string,
  className: PropTypes.string,
  emailData: emailDataPropTypes,
};

GetinTouch.defaultProps = {
  autoid: "get_into_touch",
  header: "",
  body: "",
  className: "",
  emailData: [],
};

export default GetinTouch;
