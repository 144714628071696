import React from "react";
import getConfig from "next/config";

import clsx from "clsx";
import { node, string, bool } from "prop-types";
import { LinkWithFallback } from "@marcel/react-component-library/es/atoms/Link";
import {
  LaunchIcon,
  RightChevronIcon,
  LeftChevronIcon,
  PlaySmallIcon,
  DownloadIcon,
} from "Components/Icon";
import { useRouter } from "next/router";
import styles from "./Link.scss";

const basePath = (
  getConfig().publicRuntimeConfig.basePath || ""
).replace(/\/$/, "");

/**
 * Common propTypes
 * @prop {React.Node} props.children children
 * @prop {String} props.href `href` value for the inner `<a>`
 */
const linkProps = {
  autoid: string,
  children: node.isRequired,
  href: string.isRequired,
  newTab: bool,
};

const linkDefaultProps = {
  autoid: "link",
  newTab: false,
};

/**
 * Link intended for internal targets
 * @see {@link linkProps}
 * @returns {React.Node} node
 */
const Link = ({ autoid, children, className, newTab, ...props }) => (
  <a
    data-autoid={autoid}
    className={clsx(styles.root, className)}
    target={newTab ? "_blank" : null}
    rel={newTab ? "external noopener noreferrer" : null}
    {...props}
  >
    {children}
  </a>
);

Link.propTypes = linkProps;
Link.defaultProps = linkDefaultProps;

export default Link;

/**
 * Just a regular link with base styles applied
 * TODO: Rework the module to make this the default export
 * @see {@link linkProps}
 * @returns {React.Node} node
 */
export const PlainLink = ({
  autoid,
  children,
  className,
  href,
  newTab,
  ...props
}) => (
  <a
    data-autoid={autoid}
    className={clsx(styles.root, className)}
    href={href}
    target={newTab ? "_blank" : null}
    rel={newTab ? "external noopener noreferrer" : null}
    {...props}
  >
    {children}
  </a>
);

PlainLink.propTypes = linkProps;
PlainLink.defaultProps = linkDefaultProps;

/**
 * Link intended for external targets
 * @see {@link linkProps}
 * @returns {React.Node} node
 */
export const LaunchLink = ({
  autoid,
  children,
  className,
  href,
  newTab,
  ...props
}) => (
  <a
    data-autoid={autoid}
    className={clsx(styles.root, className)}
    href={href}
    rel="external noopener noreferrer"
    target="_blank"
    {...props}
  >
    {children} <LaunchIcon />
  </a>
);

LaunchLink.propTypes = linkProps;
LaunchLink.defaultProps = linkDefaultProps;

/**
 * Link intended for internal targets
 * @see {@link linkProps}
 * @returns {React.Node} node
 */
export const NextLink = ({ children, className, href, ...props }) => (
  <Link className={clsx(styles.root, className)} href={href} {...props}>
    {children} <RightChevronIcon />
  </Link>
);

NextLink.propTypes = linkProps;
NextLink.defaultProps = linkDefaultProps;

/**
 * Link intended for internal targets
 * @see {@link linkProps}
 * @returns {React.Node} node
 */
export const ReturnLink = ({
  autoid,
  children,
  className,
  newTab,
  href,
  ...props
}) => (
  <a
    data-autoid={autoid}
    className={clsx(styles.root, className)}
    href={href}
    target={newTab ? "_blank" : null}
    rel={newTab ? "external noopener noreferrer" : null}
    {...props}
  >
    <LeftChevronIcon /> {children}
  </a>
);

ReturnLink.propTypes = linkProps;
ReturnLink.defaultProps = linkDefaultProps;

/**
 * Link intended for video heroes
 * @see {@link linkProps}
 * @returns {React.Node} node
 */
export const PlayLink = ({
  autoid,
  children,
  className,
  href,
  ...props
}) => (
  <a
    data-autoid={autoid}
    className={clsx(styles.root, className)}
    href={href}
    {...props}
  >
    <PlaySmallIcon /> {children}
  </a>
);

PlayLink.propTypes = linkProps;
PlayLink.defaultProps = linkDefaultProps;

/**
 * Link intended for downloadable files
 * @see {@link linkProps}
 * @returns {React.Node} node
 */
export const DownloadLink = ({
  autoid,
  children,
  className,
  href,
  ...props
}) => (
  <a
    data-autoid={autoid}
    className={clsx(styles.root, className)}
    href={href}
    {...props}
    download
  >
    {children} <DownloadIcon />
  </a>
);

DownloadLink.propTypes = linkProps;
DownloadLink.defaultProps = linkDefaultProps;

/**
 * Renders a link for articles
 *
 * @see {@link articleLinkProps}
 *
 * @param {React.ReactNode} props.children - any kind of component
 *
 * @returns {React.ReactNode} a JSX.Element
 */
export const ArticleLink = ({
  title,
  path,
  children,
  type,
  ...props
}) => {
  const router = useRouter();

  const asUrl =
    path &&
    `${basePath}/article/${path.replace(
      "/content/dam/content-admin/public/articles/",
      "",
    )}`;

  /**
   * @param {any} e the click event
   */
  const handleClick = e => {
    e.preventDefault();
    router.push(
      {
        pathname: "/article",
        query: {
          articleTitle: title,
          articlePath: path,
        },
      },
      asUrl,
    );
  };

  return type === "External Link" ? (
    path && (
      <LinkWithFallback {...props} href={path || null}>
        {children}
      </LinkWithFallback>
    )
  ) : (
    <a {...props} href={asUrl} onClick={handleClick}>
      {children}
    </a>
  );
};

ArticleLink.propTypes = {
  children: node.isRequired,
  path: string.isRequired,
  title: string.isRequired,
  type: string,
};

ArticleLink.defaultProps = {
  type: "",
};
