import React from "react";
import clsx from "clsx";
import { bool, node, oneOf, string } from "prop-types";
import styles from "./PageGrid.scss";

/**
 * Map of `region` values to CSS classes
 */
const regionStyles = new Map([
  ["content", styles.contentCell],
  ["full", styles.fullCell],
  ["left-main", styles.leftMainCell],
  ["left-side", styles.leftSideCell],
  ["right-main", styles.rightMainCell],
  ["right-side", styles.rightSideCell],
  ["left-half", styles.leftHalfCell],
  ["right-half", styles.rightHalfCell],
  ["left-line1-line2", styles.leftLine1Line2Cell],
]);

/**
 * Grid container for page-level content regions
 * @param {Object} props component props
 * @param {String} props.as HTML tag name for root element
 * @param {String} props.className CSS class name for root element
 * @returns {React.Node} node
 */
const PageGrid = ({ as: Component, children, className, ...props }) => (
  <Component className={clsx(styles.root, className)} {...props}>
    {children}
  </Component>
);

PageGrid.propTypes = {
  as: string,
  children: node.isRequired,
};

PageGrid.defaultProps = {
  as: "div",
};

export default PageGrid;

/**
 * Page grid cell for content regions
 * @param {Object} props component props
 * @param {String} props.as HTML tag name for root element
 * @param {String} props.className CSS class name for root element
 * @param {Boolean} props.padded option to add vertical padding
 * @param {String} props.region region identifier to control cell layout
 * @returns {React.Node} node
 */
export const PageCell = ({
  as: Component,
  children,
  className,
  padded,
  region,
  ...props
}) => (
  <Component
    className={clsx(
      regionStyles.get(region),
      padded && styles.padded,
      className,
    )}
    {...props}
  >
    {children}
  </Component>
);

PageCell.propTypes = {
  as: string,
  children: node.isRequired,
  padded: bool,
  region: oneOf([
    "content",
    "full",
    "left-main",
    "left-side",
    "right-main",
    "right-side",
    "left-half",
    "right-half",
    "left-line1-line2",
  ]),
};

PageCell.defaultProps = {
  as: "div",
  padded: false,
  region: "full",
};
