import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { SkeletonShell } from "@marcel/react-component-library/es/organisms/SkeletonShell";
import { Label } from "@marcel/react-component-library/es/atoms/Label";
import ArticleCard from "./ArticleCard/ArticleCard.jsx";
import styles from "./ArticleCards.scss";

/**
 * OpenLayout component class
 * Wrapper for displaying daily digest data
 * @param {array} title Title displayed above the digest cards
 * @param {object} digestData Data used to create each of the digest cards
 * @param {number} cardLimit The maximum number of cards to be displayed
 * @param {string} className Styles applied to the component
 * @param {string} LinkComponent The link component used for the digest card link
 * @param {string} autoid The autoid of the component for automation testing attribute
 * @param {boolean} isLoading Boolean for if the component is still loading
 * @param {string} ctaText - The text of the cta button
 * @param {string} ctaLink - The link of the cta button
 * @param {object} labels Generic labels from API
 * @constructor
 */
const ArticleCards = ({
  title,
  digestData,
  cardLimit,
  className,
  autoid,
  isLoading,
  labels,
}) => {
  if (Array.isArray(digestData) && digestData.length > 0) {
    return (
      <SkeletonShell
        className={clsx(styles.ArticleCards, className)}
        isLoading={isLoading}
      >
        <div className={styles.tabBox}>
          {title
            ? title.map((tabHeading, index) => (
                <h2
                  data-autoid={`${autoid}_heading_${index}`}
                  className={styles.title}
                  key={`${autoid}_${index}`}
                >
                  <Label className={styles.tabHeading}>
                    {tabHeading}
                  </Label>
                </h2>
              ))
            : null}
        </div>

        <ul className={styles.cardGallery}>
          {digestData.map((card, index) =>
            index && index + 1 <= cardLimit ? (
              <ArticleCard
                key={`digestcard${index}`}
                {...card}
                index={index}
                labels={labels}
              />
            ) : null,
          )}
        </ul>
      </SkeletonShell>
    );
  }
  return null;
};

export const digestDataPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    author: PropTypes.string,
    description: PropTypes.string,
    digestImageDesktop: PropTypes.shape({
      altText: PropTypes.string,
      value: PropTypes.string,
    }),
    estimatedReadTime: PropTypes.string,
    path: PropTypes.string,
    title: PropTypes.string,
    type: PropTypes.string,
  }),
);

ArticleCards.propTypes = {
  title: PropTypes.array,
  digestData: PropTypes.array,
  cardLimit: PropTypes.number,
  className: PropTypes.string,
  autoid: PropTypes.string,
  isLoading: PropTypes.bool,
  labels: PropTypes.shape({
    byAuthorLabel: PropTypes.string,
  }),
};

ArticleCards.defaultProps = {
  title: [],
  digestData: [],
  cardLimit: 6,
  className: null,
  autoid: "dd",
  isLoading: false,
  labels: {},
};

export default ArticleCards;
