import React from "react";
import { oneOf, string } from "prop-types";
import NotFoundPage from "./error/404";
import ForbiddenPage from "./error/403";
import ServerErrorPage from "./error/500";

// Keep these sorted high to low
const STATUS_CODES = [500, 404, 403];

/**
 * Match a given status code to the closest range
 * @param {Number} code HTTP status code
 * @returns {Number} matched status code
 */
const matchStatusCode = code =>
  STATUS_CODES.find(val => code >= val) || null;

/**
 * Error page handler component
 * @param {Object} props component props
 * @param {Number} props.statusCode HTTP status code
 * @returns {React.Node} node
 */
const Page = ({ statusCode, message }) => {
  switch (statusCode) {
    case 403:
      return <ForbiddenPage />;
    case 404:
      return <NotFoundPage />;
    case 500:
    default:
      return <ServerErrorPage message={message} />;
  }
};

Page.propTypes = {
  statusCode: oneOf([null, 429, ...STATUS_CODES]),
  message: string,
};

Page.defaultProps = {
  statusCode: null,
  message: "",
};

Page.getInitialProps = ({ res, err }) => {
  // Look for response (SSR), then error (client-side)
  const { statusCode } = res || err;
  return {
    statusCode: matchStatusCode(Number(statusCode)),
  };
};

export default Page;
