import React from "react";
import PropTypes from "prop-types";
import Head from "Components/Head";
import FallbackPage from "Components/Page/FallbackPage";
import { RichText } from "@marcel/react-component-library/es/molecules/RichText";

/**
 * BaseError component
 * @param {string} [autoid='error'] The autoid of the component for automation testing
 * @param {string} [body] In Rich Text format
 * @param {Object} [emoji] The title component data, including the heading and an emoji
 * @param {string} [eyebrow] The eyebrow of the page
 * @param {Object} [heading] The title component data, including the heading and an emoji
 * @param {string} [heading.text] The heading text
 * @param {Object[]} [links] The page links data
 * @param {string} [metaTitle] The string page title name
 * @returns {React.Node} node
 */
const BaseError = ({
  autoid,
  body,
  emoji,
  eyebrow,
  heading,
  links,
  message,
  metaTitle,
}) => (
  <FallbackPage
    heading={heading.text}
    emoji={emoji}
    subheading={eyebrow}
    link={links}
  >
    <Head title={metaTitle} />
    <RichText autoid={`${autoid}_description`}>{body}</RichText>
    {process.env.VERBOSE_VISIBLE === "true" && <p>{message}</p>}
  </FallbackPage>
);

/**
 * @property propTypes
 * @description Defined property types for component
 * @type {{query: *}}
 */
export const baseErrorPropTypes = {
  message: PropTypes.string,
  metaTitle: PropTypes.string,
  autoid: PropTypes.string,
  eyebrow: PropTypes.string,
  heading: PropTypes.shape({
    text: PropTypes.string,
    emoji: PropTypes.string,
    emojiTitle: PropTypes.string,
  }),
  body: PropTypes.string,
  emoji: PropTypes.shape({
    emojiIcon: PropTypes.string,
    emojiTitle: PropTypes.string,
  }),
  links: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      text: PropTypes.string,
      link: PropTypes.string,
    }),
  ),
};

/**
 * @property defaultProps
 * @description Default property types for component
 */
export const baseErrorDefaultProps = {
  message: "",
  metaTitle: "",
  autoid: "error",
  eyebrow: "",
  heading: {
    text: "",
    emoji: "",
    emojiTitle: "",
  },
  body: "",
  emoji: {
    emojiIcon: "",
    emojiTitle: "",
  },
  links: [
    {
      id: "",
      text: "",
      link: "",
    },
  ],
};

BaseError.propTypes = baseErrorPropTypes;
BaseError.defaultProps = baseErrorDefaultProps;
BaseError.displayName = "BaseError";

export default BaseError;
