import React from "react";
import { string } from "prop-types";
import NextHead from "next/head";

/**
 * A wrapper around the Next <Head> component that allows the common
 * items (title, description, keywords, etc.) to be supplied as props instead of children.
 * @param {Object} props component props
 * @param {String} props.title content for <title>
 * @param {String} props.description content for <meta name="description">
 * @param {String} props.keywords content for <meta name="keywords">
 * @returns {React.Node} node
 */
const Head = ({ title, description, keywords }) => (
  <NextHead>
    {title && <title key="title">{title}</title>}
    {description && (
      <meta
        key="description"
        name="description"
        content={description}
      />
    )}
    {keywords && (
      <meta key="keywords" name="keywords" content={keywords} />
    )}
  </NextHead>
);

Head.propTypes = {
  title: string,
  description: string,
  keywords: string,
};

Head.defaultProps = {
  title: "",
  description: "",
  keywords: "",
};

export default Head;
