/**
 * MAP data for article detail [FROM Fx portal] https://us.tools.publicis.sapient.com/bitbucket/projects/MARCEL/repos/marcel-fx-portal-content/browse/methods/mapArticleData.js
 * @param {object} properties object of article detail keys
 * @returns {object} mapped article data wrt RCL components
 */
const ArticleDetailDataMapping = ({
  properties: {
    title = "",
    elements: {
      heroAsset: { value, renditions = {} },
      estimatedReadTime,
      author = {},
      body,
      ...props
    },
  },
}) => {
  const { value: videoLink } = props;
  const authorData = {
    eyebrow: "About the author",
    entities: [],
  };
  authorData.entities.push({
    image: author.profilePicture ? author.profilePicture : "",
    name:
      author.authorName && author.authorName.value
        ? author.authorName.value
        : "",
    title:
      author.authorTitle && author.authorTitle.value
        ? author.authorTitle.value
        : "",
    details: author.authorBio ? author.authorBio : "",
    link:
      author.authorLinks &&
      author.authorLinks.value &&
      Array.isArray(author.authorLinks.value) &&
      author.authorLinks.value.length > 0
        ? author.authorLinks.value[0].id
        : "",
    organization: [],
  });
  authorData.entities[0].organization.push(
    {
      title:
        author.authorAgencyLocation && author.authorAgencyLocation.value
          ? author.authorAgencyLocation.value.split(" &")[0]
          : "",
      link: "",
    },
    {
      title:
        author.authorAgencyLocation && author.authorAgencyLocation.value
          ? author.authorAgencyLocation.value.split("& ")[1]
          : "",
      link: "",
    },
  );

  const data = {
    id: title,
    title,
    primaryByLine:
      author && author.authorName && author.authorName.value
        ? `By ${author.authorName.value}`
        : "",
    secondaryByLine: estimatedReadTime ? estimatedReadTime.value : "",
    items: [],
    body: body && body.value ? body.value : "",
    author: authorData,
  };
  data.items.push({
    title,
    type: "image",
    image: `${value}`,
    ogImage: `${renditions["1125x846"] || value}`,
    video: videoLink || null,
  });
  return data;
};

export default ArticleDetailDataMapping;
