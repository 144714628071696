import React from "react";
import { string, node } from "prop-types";
import clsx from "clsx";
import styles from "./Spread.scss";

const sizeStyles = new Map([
  ["small", styles.small],
  ["medium", styles.medium],
  ["large", styles.large],
]);

/**
 * Adds vertical space between its child elements
 * @param {Object} props component props
 * @param {String} props.as HTML tag for the root element
 * @param {String} props.size controls the amount of space between children
 * @returns {React.Node} node
 */
const Spread = ({ as: Component, size, children, className }) => (
  <Component
    className={clsx(styles.root, sizeStyles.get(size), className)}
  >
    {children}
  </Component>
);

Spread.propTypes = {
  as: string,
  size: string,
  children: node.isRequired,
};

Spread.defaultProps = {
  as: "div",
  size: "medium",
};

export default Spread;
