import axios, { create } from "axios";
import getConfig from "next/config";
import { getOr } from "../utils";

const { publicRuntimeConfig } = getConfig();
const {
  CONTENT_API_ENDPOINT,
  PUBLIC_CONTENT_API_ENDPOINT,
  SAS_TOKEN_API_ENDPOINT,
  CONTENT_X_FUNCTIONS_KEY,
  DOWNTIME_CONTENT_API_ENDPOINT,
  MARCEL_API_USER_HOST,
} = publicRuntimeConfig;

/**
 * The base Axios instance for all CMS requests
 * https://github.com/axios/axios#request-config
 */
const api = create({
  baseURL: CONTENT_API_ENDPOINT,
});

export default api;

/**
 * Abstract error handler
 * @param {Object} error Axios error
 * @returns {Object} error
 */
export const handleError = error => {
  // TODO: Do something besides log
  console.error(
    `❗️ ${error.message} while [${getOr(
      "",
      "config.method",
      error,
    )}] ${getOr("", "config.url", error)}`,
  );

  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    return {
      error: {
        status: error.response.status,
        message: error.message,
      },
    };
  }
  if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    return {
      error: {
        status: null,
        message: error.message,
      },
    };
  }
  // Something happened in setting up the request that triggered an Error
  return {
    error: {
      status: null,
      message: error.message,
    },
  };
};

/**
 * Fetch the current user. This is a helper for the
 * "members only" sprint fetching logic.
 * TODO: Find a better solution.
 * @returns {Object} req user
 */
export const getMe = async () => {
  try {
    const { data: user } = await axios.get("/me");
    return { user };
  } catch (err) {
    // Note that we're explicitly using a 403 here
    return handleError(new Error("403 unauthorized"));
  }
};

/**
 *
 */
export const apiArticle = create({
  baseURL: PUBLIC_CONTENT_API_ENDPOINT,
});

export const apiSas = create({
  baseURL: SAS_TOKEN_API_ENDPOINT,
});

/**
 * Calls the FX-Public-Content API
 * @param {string} apiPath The path to append to the base URL
 * @returns {Promise.<any>} unpacks the data key from the axios response
 */
export const getPublicContent = async apiPath => {
  const axiosResponse = await axios.get(apiPath, {
    baseURL: PUBLIC_CONTENT_API_ENDPOINT.replace("/v1", "/v2"),
    params: { locale: "en" },
  });

  return getOr(
    getOr({}, "data", axiosResponse),
    "data.aemResp",
    axiosResponse,
  );
};

/**
 * Calls the FX-Public-Content API
 * @param {string} apiPath The path to append to the base URL
 * @returns {Promise.<any>} unpacks the data key from the axios response
 */
export const getUserContent = async apiPath => {
  const axiosResponse = await axios.get(apiPath, {
    baseURL: MARCEL_API_USER_HOST,
  });

  return getOr(
    getOr({}, "data", axiosResponse),
    "data.aemResp",
    axiosResponse,
  );
};

export const contentKey = CONTENT_X_FUNCTIONS_KEY;

export const apiDownTime = create({
  baseURL: DOWNTIME_CONTENT_API_ENDPOINT,
});
